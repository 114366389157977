@import "../../variables";

.search-user-container-main {
  padding: 0px;

  .search-clear-icon {
    position: absolute;
    top: 7px;
    right: 13px;
    span {
      font-size: 24px;
      cursor: pointer;
      color: $theme-color;
    }
  }

  .search-input-container {
    background: $theme-color;
    color: #000000;
    padding: 5px 20px;
    max-width: 100%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;

    input {
      background: transparent;
      border: none;
      padding-bottom: 2px;
      width: 88%;
      color: #000000;
      overflow: hidden;
      outline: none;
      font-weight: 400;
      font-size: 35.5346px;
      line-height: 91.9%;

      &::placeholder {
        color: #000000;
      }
    }

    img {
      width: 35px;
    }
  }
}