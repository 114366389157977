@import '../../variables';

.settings-container {
  padding-top: 24px;

  .settings-list-container {
    .list-container {
      list-style-type: none;
      padding: 0;
      margin: 0;
      background: #161616d4;
      height: 100%;
      li {
        padding: 16px;
        font-size: 22px;
        cursor: pointer;
        &.inactive {
          &:hover {
            background-color: #212121;  
          }
        }
        &.active {
          background-color: $theme-color;
          color: #000000
        }
      }
    }
  }
}