@import "../../variables";

.homepage-container {
    padding-top: $header-height;
    margin-bottom: 80px
}
.grid-table{
    color: #fff !important;
    font-weight: 400 !important;
    font-size: 24px !important;
    line-height: 91.9% !important;
}
.css-1pe4mpk-MuiButtonBase-root-MuiIconButton-root {
    color: #fff !important;
}
.css-1usso8h-MuiDataGrid-root .MuiDataGrid-cell--textLeft {
    max-height: 70px !important;
    min-height: 70px !important;
}
.css-1usso8h-MuiDataGrid-root .MuiDataGrid-row {
    max-height: 70px !important;
    min-height: 70px !important;
}
.css-1usso8h-MuiDataGrid-root {
    border: none !important;
}
.css-1usso8h-MuiDataGrid-root .MuiDataGrid-withBorderColor {
    border: none !important;
}
.login-description{
    text-align: center;
    color: #fff;
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 25px;
}
.css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar{
    color: #fff;
    font-size: 24px;
}
.css-pqjvzy-MuiSvgIcon-root-MuiSelect-icon {
   color: #fff !important;
}