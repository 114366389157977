@import "../../variables";

.mylist-container {
    padding-top: $header-height;
    margin-bottom: 80px;

    .users-list-container {
        background-color: #141414;
        padding: 16px 0px;
        min-height: 100px;
    }
}
