@import url('https://fonts.googleapis.com/css2?family=VT323&display=swap');
@import "./variables";

body {
  margin: 0;
  font-family: 'VT323', monospace !important;
  background: #000;
}

p,
a,
h1,
h2,
h3,
h4,
h5,
h6,
div,
button,
input,
label {
  font-family: 'VT323', monospace !important;
  color: #fff;
}

code {
  font-family: 'VT323', monospace !important;
}

.MuiPaper-root {
  background-color: #000000cc !important;
}

.MuiBackdrop-root {
  background-color: transparent !important
}

.main-app-loader {
  position: fixed;
  // position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #0000009e;
  z-index: 999999;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  div {
    text-align: center;
    p {
      color: #ffffff;
      font-size: 22px;
      font-weight: 500;
    }
  }
}

.universal-table {
  thead {
    tr {
      th {
        font-family: 'VT323', monospace !important;
        color: #fff;
        border: none;
        font-size: 22px;
        color: #AEAEAE;
      }
    }
  }

  tbody {
    tr {
      td {
        font-family: 'VT323', monospace !important;
        color: #fff;
        font-size: 22px;
        border: none;
      }
    }

    tr:nth-child(odd) {
      td {
        background-color: rgba(212, 251, 95, 0.15);
      }
    }
  }
}