@import "../../variables";

.custom-select-container {

  .dropdown-trigger-el {
    width: 100%;
    // border: 1px solid grey;
    // border-radius: 40px;
    background-color: $theme-color;
    padding: 8px 16px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    color: black;

    .selected-list-label {
      letter-spacing: 0.2rem;
      font-size: 1.5rem;

      &.not-selected {
        color: grey
      }
    }
  }

  .dropdown-menu {
    position: absolute;
    top: 38px;
    right: 0px;
    background: black;
    height: auto;
    width: 98.4%;
    z-index: 2;
    overflow: hidden;
    border: 3px solid $theme-color;

    ul {
      padding: 0px;
      margin: 0px;
      list-style-type: none;

      li {
        padding: 16px;
        box-sizing: border-box;
        border-bottom: 1px solid grey;
        cursor: pointer;
        color: #ffffff;

        &.not-selected {
          &:hover {
            background-color: #2c2c2c;
          }
        }

        &.selected {
          cursor: default;
          background-color: #484848;
          color: #ffffff;
        }

        &:last-child {
          border-bottom: none;
        }
      }

    }
  }
}