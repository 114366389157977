@import "../../variables";

.header-component-main {
    height: $header-height;
    width: 100%;
    background: #000000;
    -webkit-box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    -moz-box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    position: fixed;
    z-index: 999;
    top: 0;
    backdrop-filter: blur(10px);

    .header-item {
        display: flex;
        // height: 100%;
        width: 100%;
        align-items: center;
        justify-content: space-between;

        .logo-container {
            padding-left: 20px;

            a {
                display: flex;
                align-items: center;
                text-decoration: none;
                color: black;

                .main-heading {
                    p {
                        color: rgb(0, 0, 0);
                        line-height: 91.9%;
                        -webkit-text-stroke: 1px #ffffff;
                    }
                    span {
                        display: block;
                        font-size: 1.2em;
                    }
                }
            }
        }

        .navigation-container {
            width: 100%;

            .nav-list-container {
                margin: 0;
                padding: 0;
                list-style-type: none;
                display: flex;
                float: right;

                li {
                    padding: 10px;
                    margin: 0 30px;
                    .nav-item {
                        text-decoration: none;
                        display: flex;
                        &:active {
                            color: none;
                        }
                        p {
                            color: #ffffff;
                        }
                    }
                }
            }
        }
    }

    .nav-list-container-mobile {
        display: flex;
        margin: 0;
        padding: 0;
        justify-content: space-evenly;
        align-items: center;
        height: 100%;

        li {
            display: inline;

            .nav-item-mobile {
                text-decoration: none;

                &:active {
                    color: none;
                }
            }
        }
    }

    .user-header-dropdown {
        position: absolute;
        width: 150px;
        height: auto;
        z-index: 3;
        background: #ffffff;
        right: 22px;
        top: 42px;
        border: 1px solid grey;
        border-radius: 8px;
        overflow: hidden;

        ul {
            list-style-type: none;
            margin: 0;
            padding: 0;

            li {
                * {
                    display: block;
                    padding: 8px;
                    text-decoration: none;
                    color: #000000;
                }

                &:hover {
                    * {
                        background-color: $theme-color;
                    }
                }
            }
        }
    }
}
